html {
  height: 100%;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  > * {
    flex: none;
  }
  > #wrapper {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    > * {
      flex: none;
    }
    > #container {
      flex: 1 0 auto;
    }
  }
}

#navigation {
  position: sticky;
  top: -(31px/$font-size-root)*1rem;
  @include media-breakpoint-down(xl) {
    top: 0;
  }
  z-index: $zindex-sticky;
  background: white;
}

$header-max-height: (583px/$font-size-root)*1rem;
header {
  position: relative;
  min-height: $gbwrdg-corner-size;
  max-height: $header-max-height;
  background: $primary;
  clip-path: polygon(
    $gbwrdg-corner-size 0%,
    100% 0%,
    100% calc(100% - $gbwrdg-corner-size),
    calc(100% - $gbwrdg-corner-size) 100%,
    0% 100%,
    0% $gbwrdg-corner-size
  );
  margin-bottom: (26px/$font-size-root)*1rem;
  @include media-breakpoint-down(lg) {
    max-height: 200px;
    clip-path: polygon(
    27px 0%,
    100% 0%,
    100% calc(100% - 27px),
    calc(100% - 27px) 100%,
    0% 100%,
    0% 27px
  );
  }
  body.page-index & {
    margin-bottom: 0;
  }
  .ce_image {
    figure {
      margin-bottom: 0;
    }
    img {
      width: 100%;
      height: $header-max-height;
      @include media-breakpoint-down(lg) {
        height: 200px;
      }
      object-fit: cover;
      object-position: center;
    }
    .page-index & {
      position: relative;
      &:not(.no-tint):after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: fade-out($primary, 0.25);
      }
      &.pos-christmas-2024 {
        img {
          object-position: 50% 10%;
        }
      }
    }
  }
  .ce_text {
    .page-index & {
      position: absolute;
      top: (163px/$font-size-root)*1rem;
      width: 100%;
      line-height: (60/55);
      font-size: (55px/$font-size-root)*1rem;
      @include media-breakpoint-down(lg) {
        font-size: 21px;
        line-height: (26/21);
      }
      @include media-breakpoint-down(lg) {
        top: 31px;
      }
      font-weight: 300;
      color: white;
      p {
        @extend .container;
        text-align: center;
      }
    }
    &--header {
      position: relative;
      background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.398 138.338" height="522.854" width="353"><path d="M57.079 46.996v42.89l44.613-44.603V2.4ZM150.477 89.88V46.99l-22.311-22.294-22.303 22.294v42.89l22.303-22.303z" fill="#af101d" transform="translate(-57.079 -2.4)"/><path d="m149.621 94.922-21.444-21.445-24.394 24.394-24.394-24.394-21.444 21.445 45.838 45.817Z" fill="#af101d" transform="translate(-57.079 -2.4)"/></svg>'));
      background-repeat: no-repeat;
      background-position: 20% center;
      .container {
        min-height: $header-max-height;
        @include media-breakpoint-down(lg) {
          min-height: 200px;
        }
        display: flex;
        justify-content: stretch;
      }
      &__text {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 8.5%;
        padding-right: 3.5%;
        color: white;
        text-align: center;
        @include media-breakpoint-down(lg) {
          padding-left: 0;
          padding-right: $grid-gutter-width/2;
        }
        p {
          margin-bottom: 0;
          line-height: (60/55);
          font-size: (55px/$font-size-root)*1rem;
          font-weight: 300;
          strong {
            display: block;
            margin-top: (43px/$font-size-root)*1rem;
            line-height: (50/40);
            font-size: $h1-font-size;
            font-weight: 600;
          }
          @include media-breakpoint-down(lg) {
            font-size: 21px;
            line-height: (26/21);
            & strong {
              font-size: 1rem;
            }
            & strong {
              margin-top: 0.5em;
            }
          }
        }
      }
      .image_container {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

main {
  body.page-index & {
    margin-top: (90px/$font-size-root)*1rem;
  }
  .block,
  .accordion-item {
    + .block {
      margin-top: (70px/$font-size-root)*1rem;
    }
    &.ce_text,
    &.ce_list {
      + .block.ce_text,
      + .block.ce_list {
        margin-top: 1rem;
      }
    }
    & + .block.ce_image--banner,
    &.ce_image--banner + .block {
      margin-top: (80px/$font-size-root)*1rem;
    }
  }
}

main,
footer {
  .ce_text,
  .ce_headline,
  .ce_list,
  .ce_table,
  .ce_hyperlink,
  .ce_download,
  .ce_downloads,
  .mod_article > .ce_contact,
  .mod_ivm_pro_reader,
  .ce_form,
  .mod_form,
  .mod_newslist:not(.mod_newslist--slider),
  .mod_newsreader,
  .news_latest--slider__image-wrap,
  .mod_search,
  .mod_subscribe,
  .mod_unsubscribe {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
  }
  .ce_bs_gridStart--factbox {
    max-width: calc(940px + $grid-gutter-width);
    margin-left: auto;
    margin-right: auto;
  }
}

.mod_article--background {
  padding-top: (80px/$font-size-root)*1rem;
  padding-bottom: (80px/$font-size-root)*1rem;
  background: $gray-200;
}

.mod_article--bgshape {
  background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="173.933" height="344.333" viewBox="0 0 173.933 344.333"><path fill="#f4f4f4" d="M541.3,362.2V532.6L715.235,358.7V188.269Z" transform="translate(-541.303 -188.269)"/></svg>')), url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="173.926" height="257.387" viewBox="0 0 173.926 257.387"><path fill="#f4f4f4" d="M740.613,457.76v-170.4l-86.995-86.987-86.931,86.987v170.4l86.931-86.969Z" transform="translate(-566.688 -200.373)"/></svg>'));
  background-repeat: no-repeat;
  background-position: left top, right top (87px/$font-size-root)*1rem;
  min-height: 344px;
}

$footer-triangle-size: (370px/$font-size-root)*1rem;
footer {
  margin-top: (185px/$font-size-root)*1rem;
  padding-top: (220px/$font-size-root)*1rem;
  padding-bottom: (80px/$font-size-root)*1rem;
  background: $gray-200;
  clip-path: polygon(
    0% 0%,
    calc(50% - #{$footer-triangle-size/2}) 0%,
    50% $footer-triangle-size/2,
    calc(50% + #{$footer-triangle-size/2}) 0%,
    100% 0%,
    100% 100%,
    0% 100%
  );
  @include media-breakpoint-up(xl) {
    > .inside {
      > .mod_article {
        > .row {
          > div:first-child {
            > .row {
              > div {
                &:first-child {
                  width: 29%;
                }
                &:nth-child(2),
                &:nth-child(3) {
                  width: 35.5%;
                }
              }
            }
          }
          > div:nth-child(2) {
            > .row {
              > div:first-child {
                width: 52.5%;
                padding-left: 0;
                padding-right: (8px/$font-size-root)*1rem;
              }
              > div:last-child {
                width: 47.5%;
                padding-left: (17px/$font-size-root)*1rem;
                padding-right: (13px/$font-size-root)*1rem;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
    .ce_image {
      width: 192px;
    }
    #vwc-container {
      width: 160px;
    }
  }
}
