$unit-list-spacer: (65px/$font-size-root)*1rem;

.mod_ivm_pro_filter {
  position: relative;
  top: -(26px/$font-size-root)*1rem;
  padding-top: (22px/$font-size-root)*1rem;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -(80px/$font-size-root)*1rem;
    right: 0;
    bottom: (87px/$font-size-root)*1rem;
    left: 0;
    background: $primary;
    clip-path: polygon(
      $gbwrdg-corner-size 0%,
      100% 0%,
      100% calc(100% - $gbwrdg-corner-size),
      calc(100% - $gbwrdg-corner-size) 100%,
      0% 100%,
      0% $gbwrdg-corner-size
    );
  }
  body.page-index & {
    top: 0;
    padding-top: 0;
    margin-top: -(166px/$font-size-root)*1rem;
    @include media-breakpoint-down(lg) {
      margin-top: -48px;
    }
    &:before {
      display: none;
    }
  }
  &__inner {
    position: relative;
    background: $gray-200;
    padding: (32px/$font-size-root)*1rem 0 (45px/$font-size-root)*1rem;
  }
  h2 {
    margin-bottom: (30px/$font-size-root)*1rem;
    font-size: (30px/$font-size-root)*1rem;
    font-weight: bold;
    text-align: center;
    @include media-breakpoint-down(md) {
      font-size: inherit;
    }
  }
  .ce_form {
    max-width: 75%;
    margin: 0 auto;
    @include media-breakpoint-down(xl) {
      max-width: calc(100% - #{2*$grid-gutter-width});
    }
  }
  .formbody {
    > .row {
      > div {
        position: relative;
        &:nth-child(3) ~ * {
          margin-top: $grid-gutter-width;
        }
        @include media-breakpoint-down(lg) {
          &:first-child ~ * {
            margin-top: $grid-gutter-width;
          }
        }
      }
    }
    .dropdown-menu {
      width: calc(100% - #{$grid-gutter-width});
      border-width: 0 1px 1px;
      border-color: $primary;
      border-radius: 0;
      padding: 0 (15px/$font-size-root)*1rem (12px/$font-size-root)*1rem;
      &[data-popper-placement="top-start"] {
        border-width: 1px 1px 0;
        padding-top: (12px/$font-size-root)*1rem;
        padding-bottom: 0;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background: white;
      }
      &[data-popper-placement="top-start"]:before {
        top: auto;
        bottom: -1px;
      }
    }
  }
  .btn {
    display: block;
    width: 100%;
  }
}

.mod_ivm_pro_list {
  figure {
    display: block;
    position: relative;
    margin-top: $unit-list-spacer;
    margin-bottom: 0;
    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: (268.5/358)*100%;
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h3 {
    padding-top: $unit-list-spacer;
  }
  .location {
    font-size: (20px/$font-size-root)*1rem;
    line-height: (30/20);
  }
  .data {
    display: flex;
    justify-content: space-between;
    margin-top: (17px/$font-size-root)*1rem;
    max-width: 70%;
    @include media-breakpoint-down(sm) {
      max-width: 360px;
    }
  }
  .actions {
    margin-top: (40px/$font-size-root)*1rem;
    .btn {
      &.btn-link {
        font-size: inherit;
        font-weight: 600;
        box-shadow: none;
      }
      + .btn {
        margin-left: 0.66em;
      }
    }
  }
  section + section {
    figure {
      margin-top: 2*$unit-list-spacer;
    }
    h3 {
      border-top: 3px solid $gray-200;
      margin-top: $unit-list-spacer;
    }
  }
}

.mod_ivm_pro_reader {
  h1 {
    text-align: center;
  }
  .location {
    font-size: (20px/$font-size-root)*1rem;
    line-height: (30/20);
    margin-top: (39px/$font-size-root)*1rem;
    text-align: center;
  }
  .direct-actions {
    &.block {
      margin-top: (54px/$font-size-root)*1rem;
    }
    &,
    & li {
      margin-bottom: 0;
      padding-left: 0;
      list-style: outside none none;
    }
    padding-top: (11px/$font-size-root)*1rem;
    padding-bottom: (11px/$font-size-root)*1rem;
    border-top: 3px solid $gray-200;
    border-bottom: 3px solid $gray-200;
    display: flex;
    line-height: (20/18);
    li {
      &:first-child + li {
        margin-left: auto;
      }
      a,
      button.btn.btn-link {
        display: block;
        &.icon {
          padding-right: (31px/$font-size-root)*1rem;
          background-repeat: no-repeat;
          background-position: right center;
          &-map {
            background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="m22.444 3-.178.033-5.933 2.3L9.667 3 3.4 5.111a.56.56 0 0 0-.4.533v16.8a.55.55 0 0 0 .556.556l.178-.033 5.933-2.3L16.333 23l6.267-2.111a.56.56 0 0 0 .4-.533v-16.8A.55.55 0 0 0 22.444 3ZM10.778 5.744 15.222 7.3v12.956L10.778 18.7Zm-5.556 1.1 3.334-1.122v13l-3.334 1.289Zm15.556 12.312-3.333 1.122V7.289L20.778 6Z" transform="translate(-3 -3)" fill="#be1627"/></svg>'));
            &:hover,
            &:focus {
              background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="m22.444 3-.178.033-5.933 2.3L9.667 3 3.4 5.111a.56.56 0 0 0-.4.533v16.8a.55.55 0 0 0 .556.556l.178-.033 5.933-2.3L16.333 23l6.267-2.111a.56.56 0 0 0 .4-.533v-16.8A.55.55 0 0 0 22.444 3ZM10.778 5.744 15.222 7.3v12.956L10.778 18.7Zm-5.556 1.1 3.334-1.122v13l-3.334 1.289Zm15.556 12.312-3.333 1.122V7.289L20.778 6Z" transform="translate(-3 -3)" fill="#{$link-hover-color}"/></svg>'));
            }
          }
          &-exposee {
            background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="#be1627"><path d="M10 0H2A2 2 0 0 0 .01 2L0 18a2 2 0 0 0 1.99 2H14a2.006 2.006 0 0 0 2-2V6Zm4 18H2V2h7v5h5Z"/><path d="m12 12.99-1.41-1.41L9 13.16V9H7v4.16l-1.59-1.59L4 12.99 7.99 17Z"/></svg>'));
            &:hover,
            &:focus {
              background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="#{$link-hover-color}"><path d="M10 0H2A2 2 0 0 0 .01 2L0 18a2 2 0 0 0 1.99 2H14a2.006 2.006 0 0 0 2-2V6Zm4 18H2V2h7v5h5Z"/><path d="m12 12.99-1.41-1.41L9 13.16V9H7v4.16l-1.59-1.59L4 12.99 7.99 17Z"/></svg>'));
            }
          }
        }
      }
      button.btn.btn-link {
        padding: 0;
        font-size: inherit;
        font-weight: 600;
        box-shadow: none;
      }
    }
  }
  .ce_gallery {
    .image_container {
      position: relative;
      padding-top: (430/649)*100%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .costs {
    @include media-breakpoint-up(md) {
      display: flex;
    }
    @include media-breakpoint-down(md) {
      > div + div {
        margin-top: 1em;
      }
    }
    justify-content: space-between;
    dt {
      font-weight: normal;
    }
    dd {
      margin-bottom: 0;
      font-size: (25px/$font-size-root)*1rem;
      line-height: (30/25);
      font-weight: bold;
    }
  }
  table {
    table-layout: fixed;
    margin-bottom: 0;
  }
  .energy-download {
    margin-top: (40px/$font-size-root)*1rem;
  }
  .map {
    margin-top: (50px/$font-size-root)*1rem;
    width: 100%;
    height: 308px;
  }
  .ce_text--infobox {
    margin-top: (70px/$font-size-root)*1rem;
  }
  ul.downloads {
    padding: 0;
    &,
    & li {
      list-style: outside none none;
    }
    li {
      + li {
        margin-top: (5px/$font-size-root)*1em;
      }
    }
    a {
      display: inline-block;
      padding-left: 35px;
      background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#be1627"><path d="M0 0h24v24H0z" fill="none"/><path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"/></svg>'));
      background-repeat: no-repeat;
      background-position: left center;
      &:hover,
      &:focus {
        background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#{$link-hover-color}"><path d="M0 0h24v24H0z" fill="none"/><path d="M22 16V4c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71L16 11l4 5H8l3-4zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"/></svg>'));
      }
      &.pdf {
        background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#be1627"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"/></svg>'));
        &:hover,
        &:focus {
          background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#{$link-hover-color}"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"/></svg>'));
        }
      }
    }
  }
}

.mod_ivm_pro_list,
.mod_ivm_pro_reader {
  .modal-dialog {
    margin-top: calc(#{(100px/$font-size-root)*1rem} + 3px);
    margin-bottom: 0;
    max-width: none;
  }
  .modal.fade {
    .modal-dialog {
      transform: translateX(-100%);
      transition-duration: 0.6s;
    }
    &.show {
      .modal-dialog {
        transform: none;
      }
    }
  }
  .modal-header,
  .modal-body {
    padding-left: (60px/$font-size-root)*1rem;
    padding-right: (60px/$font-size-root)*1rem;
  }
  .modal-header {
    padding-top: (50px/$font-size-root)*1rem;
  }
  .modal-body {
    padding-bottom: (100px/$font-size-root)*1rem;
  }
  .modal-title {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.mod_ivm_pro_list .data,
.mod_ivm_pro_reader .costs {
  dt {
    font-weight: normal;
  }
  dd {
    margin-bottom: 0;
    font-size: (25px/$font-size-root)*1rem;
    line-height: (30/25);
    font-weight: bold;
  }
}
