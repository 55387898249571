.ce_project_status {
  ul {
    &,
    & li {
      margin: 0;
      padding: 0;
      list-style: outside none none;
    }
    display: flex;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      display: block;
      max-width: 390px;
      margin-left: auto;
      margin-right: auto;
    }
    li {
      padding: (8px/$font-size-root)*1rem (15px/$font-size-root)*1rem;
      background: $gray-600;
      color: rgba(255, 255, 255, 0.5);
      font-size: (16px/$font-size-root)*1rem;
      line-height: (24/16);
      font-weight: 600;
      text-transform: uppercase;
      opacity: 0.5;
      &.active {
        opacity: 1;
        color: white;
      }
      + li {
        @include media-breakpoint-up(sm) {
          margin-left: (7px/$font-size-root)*1rem;
        }
        @include media-breakpoint-down(sm) {
          margin-top: (7px/$font-size-root)*1rem;
        }
      }
    }
  }
}
