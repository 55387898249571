nav {
  .invisible {
    @extend .visually-hidden-focusable;
  }
  ul,
  li {
    list-style: outside none none;
  }
}

$nav-main-bottom-dist: (28px/$font-size-root)*1rem;
$nav-main-bottom-overlap: (5px/$font-size-root)*1rem;
$nav-main-line-height: (23/18);
$nav-main-item-visual-height: $nav-main-line-height*1rem + $nav-main-bottom-dist - (2px/$font-size-root)*1rem;
$emergency-floating-padding: (15px/$font-size-root)*1rem;
.nav--main {
  position: relative;
  height: (131px/$font-size-root)*1rem;
  display: flex;
  align-items: flex-end;
  transition: height 0.3s;
  &:after {
    content: "";
    display: block;
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -3px;
    width: calc(100% - #{$gbwrdg-corner-size});
    height: 3px;
    background: $primary;
    opacity: 0;
    transition: opacity 0.3s;
    @include media-breakpoint-up(xl) {
      .nav-header-reduce & {
        opacity: 1;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    height: 80px;
  }
  &__logo {
    position: fixed;
    top: 1rem;
    left: $gbwrdg-corner-size;
    @media (min-width: map-get($grid-breakpoints, 'xl')) and (max-width: 1650px) {
      top: 12px;
      clip-path: polygon(0% 0%, 100% 0%, 100% 62px, 0% 62px);
    }
    @include media-breakpoint-down(lg) {
      top: 22px;
      left: 27px;
      svg {
        width: 110px;
        height: auto;
      }
    }
    #logo-main-slogan {
      transition: opacity 0.3s;
      @include media-breakpoint-down(lg) {
        opacity: 0;
      }
      @include media-breakpoint-up(xl) {
        .nav-header-reduce & {
          opacity: 0;
        }
      }
    }
  }
  ul {
    margin-bottom: 0;
    padding-left: 0;
  }
  .level_1 {
    position: relative;
    top: $nav-main-bottom-overlap;
    display: flex;
    justify-content: space-between;
    line-height: $nav-main-line-height;
    @media (min-width: 1200px) and (max-width: 1300px) {
      margin-left: 2.5rem;
    }
    a {
      color: $secondary;
      display: block;
      strong {
        font-weight: inherit;
      }
    }
    @include media-breakpoint-up(xl) {
      .btn.btn-link.toggle {
        display: none;
      }
    }
    li {
      &:hover > a,
      &:focus > a,
      > a:hover
      > a:focus,
      > a.active,
      > a.trail,
      > a.forward,
      > .btn.btn-link.toggle:not(.collapsed),
      > .btn.btn-link.toggle:not(.collapsed) + a {
        color: $primary;
      }
    }
    > li {
      display: block;
      position: relative;
      @include media-breakpoint-up(xl) {
        clip-path: polygon(0% 0%, 100% 0%, 100% $nav-main-item-visual-height, 0% $nav-main-item-visual-height);
        &.submenu:hover,
        &.submenu:focus {
          clip-path: none;
        }
      }
      > a,
      > .btn.btn-link.toggle {
        padding-bottom: $nav-main-bottom-dist;
        @media (min-width: 1301px) {
          font-size: (20px/$font-size-root)*1rem;
        }
        font-weight: 600;
      }
      &:hover > a,
      &:focus > a,
      > a:hover
      > a:focus,
      > a.active,
      > a.trail,
      > a.forward {
        @include media-breakpoint-up(xl) {
          &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0px;
            height: 0px;
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 14px solid $primary;
          }
        }
      }
      @include media-breakpoint-up(xl) {
        &:hover > ul,
        &:focus > ul,
        > a:hover + ul,
        > a:focus + ul {
          display: block;
        }
      }
      @include media-breakpoint-down(xl) {
        max-width: 480px;
        margin: 0 auto;
        border-top: 3px solid $gray-200;
        > a {
          width: calc(100% - 45px);
        }
        > a,
        > .btn.btn-link.toggle {
          padding: 11px 0;
          font-size: inherit;
          line-height: inherit;
        }
        > .btn.btn-link.toggle {
          position: absolute;
          right: -8px;
          top: 0;
          width: 45px;
          transition: transform 0.15s linear;
          &:not(.collapsed) {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  .level_2 {
    @include media-breakpoint-up(xl) {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: $zindex-dropdown;
      padding: (23px/$font-size-root)*1rem (20px/$font-size-root)*1rem;
      background: white;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
      text-align: center;
      white-space: nowrap;
    }
    > li {
      > a {
        padding: (7px/$font-size-root)*1rem 0;
      }
      @include media-breakpoint-down(xl) {
        > a {
          padding: (13px/$font-size-root)*1rem 0;
        }
        &:last-child > a {
          padding: (13px/$font-size-root)*1rem 0 (26px/$font-size-root)*1rem;
        }
      }
    }
  }
  &__emergency {
    position: absolute;
    top: (85px/$font-size-root)*1rem;
    @media (min-width: 1301px) {
      top: (82px/$font-size-root)*1rem;
    }
    right: $gbwrdg-corner-size;
    line-height: $nav-main-line-height;
    transition: top 0.3s, opacity 0.2s, visibility 0.2s;
    @media (max-width: 1650px) {
      top: (27px/$font-size-root)*1rem;
    }
    @include media-breakpoint-up(xl) {
      .nav-header-reduce & {
        opacity: 0;
        visibility: hidden;
      }
    }
    @include media-breakpoint-down(xl) {
      display: none;
    }
    &__link {
      position: relative;
      display: block;
      padding-right: (57px/$font-size-root)*1rem;
      @media (min-width: 1301px) {
        font-size: (20px/$font-size-root)*1rem;
      }
      font-weight: 600;
      color: $secondary;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: -(5px/$font-size-root)*1rem;
        right: 0;
        width: (30px/$font-size-root)*1rem;
        height: (28px/$font-size-root)*1rem;
        background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="27.5" viewBox="0 0 30 27.5"><path fill="#be1627" d="M30 13.75V10a3.75 3.75 0 0 0-3.75-3.75H3.75A3.75 3.75 0 0 0 0 10v3.75h7.5v2.5H0v10a1.25 1.25 0 0 0 1.25 1.25h27.5A1.25 1.25 0 0 0 30 26.25v-10h-7.5v-2.5Zm-8.75 5h-1.375a5 5 0 0 1-.5 1.25l1 1-1.75 1.75-1-1-1.25.5v1.5H13.75v-1.375l-1.25-.5-1 1L9.75 21l1-1a5 5 0 0 1-.5-1.25h-1.5v-2.5h1.375a5 5 0 0 1 .5-1.25l-1-1 1.75-1.75 1 1 1.25-.5v-1.5h2.5v1.375l1.25.5 1-1 1.75 1.75-1 1a5 5 0 0 1 .5 1.25h1.625v2.625Z"/><circle fill="#be1627" cx="2.5" cy="2.5" r="2.5" transform="translate(12.5 15)"/><path fill="#be1627" d="M11.25 2.5h7.5V5h2.5V1.25A1.25 1.25 0 0 0 20 0H10a1.25 1.25 0 0 0-1.25 1.25V5h2.5Z"/></svg>'));
        background-repeat: no-repeat;
        background-position: right top;
        background-size: (30px/$font-size-root)*1rem auto;
      }
    }
  }
  .emergency-floating {
    position: fixed;
    display: flex;
    right: $gbwrdg-corner-size - $emergency-floating-padding;
    width: (60px/$font-size-root)*1rem;
    height: (60px/$font-size-root)*1rem;
    background: white;
    border-radius: 50%;
    box-shadow: 0px 0px 7px #9C9B9B66;
    transition: transform 0.2s, opacity 0.2s;
    @include media-breakpoint-up(xl) {
      top: 50%;
      transform: translateY(-50%) scale(0);
      opacity: 0;
      .nav-header-reduce & {
        transform: translateY(-50%) scale(1);
        opacity: 1;
      }
    }
    @include media-breakpoint-down(xl) {
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      background: $primary;
      .ua-samsung-browser & {
        left: auto;
        right: 16px;
        bottom: 16px;
        transform: none;
      }
    }
    &__link {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: 1301px) {
        font-size: (20px/$font-size-root)*1rem;
      }
      font-weight: 600;
      > span {
        position: absolute;
        right: 100%;
        bottom: (14px/$font-size-root)*1rem; // Visual alignment with the icon
        @media (min-width: 1301px) {
          bottom: (13px/$font-size-root)*1rem; // Visual alignment with the icon
        }
        padding-right: (12px/$font-size-root)*1rem;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s, visibility 0.2s;
      }
      &:hover,
      &:focus {
        svg {
          path,
          circle {
            fill: $link-hover-color;
          }
        }
        > span {
          visibility: visible;
          opacity: 1;
        }
      }
      @include media-breakpoint-down(xl) {
        border-radius: 50%;
        &,
        &:hover,
        &:focus {
          svg {
            path,
            circle {
              fill: white;
            }
          }
        }
        &:hover,
        &:focus {
          background-color: $link-hover-color;
        }
        > span {
          display: none;
        }
      }
    }
  }
}

#toggle-menu,
#toggle-menu + label {
  display: none;
}

@include media-breakpoint-down('xl') {
  $menu-trans-dur: 0.25s;

  #toggle-menu {
    opacity: 0;
  }
  #toggle-menu,
  #toggle-menu + label {
    display: block;
    position: fixed;
    top: 31px;
    right: 26px;
  }

  #toggle-menu,
  #toggle-menu + label {
    z-index: $zindex-sticky;
  }

  #toggle-menu + label {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      width: 30px;
      height: 4px;
      background: $gray-600;
      transition-delay: 0.1s;
    }
    &:before {
      top: 5px;
      transition: top 0.15s linear, height 0.15s linear, background 0.15s;
    }
    &:after {
      bottom: 5px;
      transition: bottom 0.15s linear, height 0.15s linear, background 0.15s;
    }

    span {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      &:before,
      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 13px;
        left: 0;
        width: 30px;
        height: 4px;
        background: $gray-600;
        transition: transform 0.15s linear, background 0.15s;
        transition-delay: 0s, 0.1s;
      }
    }
  }

  #toggle-menu + label + .level_1 {
    position: fixed;
    z-index: ($zindex-sticky - 1);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow: auto;
    padding: 80px 26px 26px;
    background: white;
    display: block;
    visibility: hidden;
    transform: translateX(-100%);
    transition: visibility 2*$menu-trans-dur, transform (2*$menu-trans-dur);
  }

  #toggle-menu:checked + label {
    &:before {
      top: 0;
      height: 15px;
      background: #be1627;
      transition-delay: 0s;
    }
    &:after {
      bottom: 0;
      height: 15px;
      background: #be1627;
      transition-delay: 0s;
    }
    span {
      &:before,
      &:after {
        background: white;
        transition-delay: 0.1s, 0s;
      }
      &:before {
        transform: rotate(45deg) scaleX(80%);
      }
      &:after {
        transform: rotate(-45deg) scaleX(80%);
      }
    }
  }

  #toggle-menu:checked + label + .level_1 {
    visibility: visible;
    transform: translateX(0);
    transition: visibility 0s, transform (2*$menu-trans-dur);
  }
}
