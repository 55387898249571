.ce_bs_gridStart--factbox {
  > div {
    display: flex;
  }
  .ce_text {
    background: $gray-200;
    padding: (22px/$font-size-root)*1rem $grid-gutter-width/2 (31px/$font-size-root)*1rem;
    text-align: center;
    strong {
      font-size: (25px/$font-size-root)*1rem;
    }
    p:first-child {
      margin-bottom: (21px/$font-size-root)*1rem;
    }
    p:not(:first-child) {
      margin-bottom: 0;
      font-size: (16px/$font-size-root)*1rem;
    }
  }
}
