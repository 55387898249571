.slick-prev,
.slick-next {
  width: (30px/$font-size-root)*1rem;
  height: (50px/$font-size-root)*1rem;
  color: $link-color;
  &:hover,
  &:focus {
    color: $link-hover-color;
  }
  &.slick-disabled {
    color: $gray-500;
    cursor: default;
  }
  &:before {
    display: none;
  }
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}
.slick-prev {
  left: -(79px/$font-size-root)*1rem;
}
.slick-next {
  right: -(79px/$font-size-root)*1rem;
  svg {
    transform: rotate(180deg);
  }
}
.slick-dots {
  bottom: -(37px/$font-size-root)*1rem;;
  li {
    button {
      &:before {
        -webkit-text-stroke: 2px $link-color;
      }
      &:hover {
        &:before {
          color: $link-hover-color;
          -webkit-text-stroke: 2px $link-hover-color;
        }
      }
    }
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 0;
  padding-bottom: 0;
}
.slick-slide {
  > * > * {
    vertical-align: top;
  }
}
