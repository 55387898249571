.btn:not(.btn-link) {
  text-transform: uppercase;
  font-weight: 600;
  &.btn-primary {
    &:hover,
    .btn-check:focus + &,
    &:focus,
    .btn-check:checked + &,
    .btn-check:active + &,
    &:active,
    &.active,
    &:active:focus,
    &.active:focus {
      background-color: $gray-600;
    }
  }
  .ce_hyperlink--diary & {
    padding-right: (51px/$font-size-root)*1rem;
    background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="19.5" viewBox="0 0 22 19.5"><path d="M22.47 5.2A10.571 10.571 0 0 0 21 4.59v12.03a10.288 10.288 0 0 0-3.5-.62 10.6 10.6 0 0 0-5.5 1.58V5.48A10.928 10.928 0 0 0 1.53 5.2a.973.973 0 0 0-.53.88v12.08a.988.988 0 0 0 1 .99 1.065 1.065 0 0 0 .48-.12A8.694 8.694 0 0 1 6.5 18a9.044 9.044 0 0 1 5.5 2 9.044 9.044 0 0 1 5.5-2 8.582 8.582 0 0 1 4.02 1.04 1.065 1.065 0 0 0 .48.12.994.994 0 0 0 1-.99V6.08a.973.973 0 0 0-.53-.88ZM10 16.62a10.189 10.189 0 0 0-7 0V6.71a8.924 8.924 0 0 1 7 .01ZM19 .5l-5 5V15l5-4.5Z" transform="translate(-1 -.5)" fill="#fff"/></svg>'));
    background-repeat: no-repeat;
    background-position: right (17px/$font-size-root)*1rem center;
  }
  &.btn-download {
    padding-right: (45px/$font-size-root)*1rem;
    background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="#fff"><path d="M10 0H2A2 2 0 0 0 .01 2L0 18a2 2 0 0 0 1.99 2H14a2.006 2.006 0 0 0 2-2V6Zm4 18H2V2h7v5h5Z"/><path d="m12 12.99-1.41-1.41L9 13.16V9H7v4.16l-1.59-1.59L4 12.99 7.99 17Z"/></svg>'));
    background-repeat: no-repeat;
    background-position: right (15px/$font-size-root)*1rem center;
  }
  &.btn-select {
    position: relative;
    padding-right: (40px/$font-size-root)*1rem;
    background: white;
    font-weight: normal;
    text-transform: none;
    text-align: left;
    background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="13.999" viewBox="0 0 6.35 3.704" fill="#BE1627"><path d="M6.35.561 3.175 3.704 0 .561.566 0l2.609 2.582L5.783 0Z"/></svg>'));
    background-repeat: no-repeat;
    background-position: right (19px/$font-size-root)*1rem center;
    background-size: (12px/$font-size-root)*1rem auto;
    &:before {
      content: "";
      display: block;
      position: absolute;
      border: 1px solid $primary;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

main .block + .ce_hyperlink.block {
  margin-top: (30px/$font-size-root)*1rem;
}

.btn-close {
  &:hover,
  &:focus,
  &:active {
    filter: brightness(75%) contrast(220%);
  }
}
