.ce_text--infobox {
  background: $gray-200;
  padding: (30px/$font-size-root)*1rem (97px/$font-size-root)*1rem (37px/$font-size-root)*1rem (164px/$font-size-root)*1rem;
  clip-path: polygon(
    $gbwrdg-corner-size-content 0%,
    100% 0%,
    100% 100%,
    0% 100%,
    0% $gbwrdg-corner-size-content
  );
  background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M15.5 9.5h3v3h-3Zm0 6h3v9h-3ZM17 2a15 15 0 1 0 15 15A15.005 15.005 0 0 0 17 2Zm0 27a12 12 0 1 1 12-12 12.016 12.016 0 0 1-12 12Z" transform="translate(-2 -2)" style="fill:#8d1921"/></svg>'));
  background-repeat: no-repeat;
  background-position: (97px/$font-size-root)*1rem (41px/$font-size-root)*1rem;
  color: $gray-600;
  font-weight: 600;
  strong {
    font-weight: inherit;
    color: $body-color;
  }
  * {
    margin-bottom: 0;
  }
}
