.checklist {
  padding-left: (35px/$font-size-root)*1rem;
  &,
  & li {
    list-style: outside none none;
  }
  li {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: ((($line-height-base*$font-size-root - $font-size-root) / 2) / $font-size-root)*1rem;
      top: $line-height-base;
      left: -(35px/$font-size-root)*1rem;
      width: (20px/$font-size-root)*1rem;
      height: (20px/$font-size-root)*1rem;
      background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40.05" height="40"><path d="M20 40q-4.25 0-7.9-1.525-3.65-1.525-6.35-4.225-2.7-2.7-4.225-6.35Q0 24.25 0 20q0-4.2 1.525-7.85Q3.05 8.5 5.75 5.8q2.7-2.7 6.35-4.25Q15.75 0 20 0q3.75 0 7 1.2t5.85 3.3L30.7 6.65q-2.2-1.75-4.9-2.7Q23.1 3 20 3 12.75 3 7.875 7.875T3 20q0 7.25 4.875 12.125T20 37q7.25 0 12.125-4.875T37 20q0-1.5-.225-2.925-.225-1.425-.675-2.775l2.3-2.3q.8 1.85 1.2 3.85.4 2 .4 4.15 0 4.25-1.55 7.9-1.55 3.65-4.25 6.35-2.7 2.7-6.35 4.225Q24.2 40 20 40Zm-2.95-10.9L8.8 20.8l2.25-2.25 6 6 20.7-20.7 2.3 2.25z" fill="#9C9B9B"/></svg>'));
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left top;
    }
  }
}
