dialog.data-consent {
  --primary-color: #{$primary};
  --btn-border-radius: 0;

  .data-consent-focus-catch + img {
    display: block;
    margin: 0;
  }
  button {
    margin-bottom: 0;
  }
  @include media-breakpoint-down(sm) {
    padding: 2em;
    .dialog-caption {
      margin-right: -2em;
    }
    .data-consent-transatlantic-services-confirmation > * {
      flex: 1 0 200px;
    }
  }
}

.data-consent-notification {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  z-index: 1000;
  padding: 0.75em 1.5em;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.1), 0 6px 10px 0 rgba(0,0,0,.07), 0 1px 18px 0 rgba(0,0,0,.06);
  &:not(.show) {
    display: none;
  }
  p {
    margin-bottom: 0.33em;
  }
  button {
    margin-bottom: 0;
  }
}
