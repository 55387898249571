.ce_contact {
  .mod_article > & > .row > .col-md-4 {
    width: ((2/12)*1110/940)*100%;
  }
  margin-top: (50px/$font-size-root)*1rem;
  figure {
    margin-bottom: 0;
  }
  img {
    border-radius: 50%;
  }
  .name {
    font-size: (28px/$font-size-root)*1rem;
    line-height: (38/28);
    margin-bottom: 0;
  }
  .function {
    font-size: (16px/$font-size-root)*1rem;
    + * {
      margin-top: (24px/$font-size-root)*1rem;
    }
  }
  p.phone,
  p.email {
    margin-bottom: 0;
    padding-left: (31px/$font-size-root)*1rem;
    background-repeat: no-repeat;
    background-position: left center;
  }
  p.phone + p.email {
    margin-top: (4px/$font-size-root)*1rem;
  }
  p.phone {
    background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#6F6F6E" width="18" viewBox="0 0 18 18" height="18"><path d="M3.62 7.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V17c0 .55-.45 1-1 1C7.61 18 0 10.39 0 1c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02z"/></svg>'));
  }
  p.email {
    background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#6F6F6E" width="20" viewBox="0 0 20 16" height="16"><path d="M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2Zm0 4-8 5-8-5V2l8 5 8-5z"/></svg>'));
  }
  a {
    font-weight: normal;
  }
}
