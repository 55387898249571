.ce_gallery {
  max-width: 649px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: (37px/$font-size-root)*1rem;
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: outside none none;
  }
  // Avoid flash of all slides appearing underneath each other before slick init
  > ul:not(.slick-initialized) li + li {
    display: none;
  }
  .image_container {
    margin-bottom: 0;
    img {
      main & {
        clip-path: none;
      }
    }
  }
}
