.mod_newslist {
  .image_container img {
    clip-path: none;
  }
}

.mod_newslist--slider {
  .slick-prev,
  .slick-next {
    z-index: 1;
    top: 0;
    transform: none;
  }
  .slick-prev {
    left: 0;
    @include media-breakpoint-down(xl) {
      left: -5px;
    }
  }
  .slick-next {
    right: 0;
    @include media-breakpoint-down(xl) {
      right: -5px;
    }
  }
  .ce_headline {
    margin-bottom: 1rem;
  }
  .ce_text {
    margin-bottom: (30px/$font-size-root)*1rem;
  }
  .more {
    text-align: center;
  }
  @include media-breakpoint-down(xl) {
    .layout_latest {
      > * {
        max-width: calc(100% - 70px);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.news_latest--slider {
  &__image-wrap {
    &--with-video {
      .image_container {
        a {
          display: table;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            width: 60px;
            height: 60px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z"/></svg>'));
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            opacity: 0.8;
            transition: transform 0.2s, opacity 0.2s;
          }
          &:hover,
          &:focus {
            &:before {
              transform: translate(-50%, -50%) scale(1.1);
              opacity: 0.9;
            }
          }
        }
      }
    }
  }
}

.mod_newslist--diary {
  padding-top: (80px/$font-size-root)*1rem;
  padding-bottom: (80px/$font-size-root)*1rem;
  background-image: linear-gradient(to right, $primary, $primary);
  background-size: 1px;
  background-repeat: repeat-y;
  background-position: center;
}

.news_latest--diary {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: (67px/$font-size-root)*1rem;
    background: $gray-200;
  }
  main &.block + &.block {
    margin-top: (150px/$font-size-root)*1rem;
    &:nth-child(odd) {
      margin-top: (125px/$font-size-root)*1rem;
    }
  }
  .row {
    position: relative;
  }
  h2 {
    margin-top: (10px/$font-size-root)*1rem;
    color: $gray-600;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .info {
      font-size: (20px/$font-size-root)*1rem;
      font-weight: 600;
    }
    .more {
      margin-bottom: 0;
    }
  }
  .image_container {
    margin-bottom: 0;
    @include media-breakpoint-down(lg) {
      text-align: center;
    }
    img {
      clip-path: none;
    }
  }
  &:nth-child(odd) {
    &:before {
      top: -(20px/$font-size-root)*1rem;
      bottom: -(65px/$font-size-root)*1rem;
      clip-path: polygon(
        0% 0%,
        100% 0%,
        100% calc(100% - #{(65px/$font-size-root)*1rem}),
        0% 100%
      );
    }
    .news_latest--diary__image {
      .image_container {
        @include media-breakpoint-up(lg) {
          margin-right: -($grid-gutter-width/2);
        }
      }
    }
    .news_latest--diary__text {
      @include media-breakpoint-down(lg) {
        margin-top: 1.5rem;
      }
      > * {
        @include media-breakpoint-up(lg) {
          padding-left: $grid-gutter-width;
        }
      }
    }
  }
  &:nth-child(even) {
    &:before {
      top: -(65px/$font-size-root)*1rem;
      bottom: -(20px/$font-size-root)*1rem;
      clip-path: polygon(
        0% (65px/$font-size-root)*1rem,
        100% 0%,
        100% 100%,
        0% 100%
      );
    }
    .news_latest--diary__image {
      order: 1;
      margin-left: 0;
      @include media-breakpoint-down(lg) {
        margin-top: 1.5rem;
      }
      .image_container {
        @include media-breakpoint-up(lg) {
          margin-left: -($grid-gutter-width/2);
        }
      }
    }
    .news_latest--diary__text {
      @extend .offset-lg-1;
      > * {
        @include media-breakpoint-up(lg) {
          padding-right: $grid-gutter-width;
        }
      }
    }
  }
  .modal-content {
    background-color: $gray-200;
  }
  .modal-body {
    padding: (57px/$font-size-root)*1rem (82px/$font-size-root)*1rem (52px/$font-size-root)*1rem;
  }
  .modal {
    .btn-close {
      margin-right: -0.25em;
    }
  }
}
