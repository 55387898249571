$primary: #BE1627;
$gray-200: #F0F0F0;
$gray-500: #9C9B9B;
$gray-600: #6F6F6E;

$body-color: black;

$link-decoration: none;
$link-hover-color: #8D1921;

$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-size-root: 18px;
$line-height-base: (26/18);
//$spacer: (60px/$font-size-root)*1rem;
$paragraph-margin-bottom: 1em;
$font-weight-bold: 600;
$headings-line-height: (45/40);
$headings-font-weight: $font-weight-bold;
$headings-color: $primary;
$h1-font-size: (40px/$font-size-root)*1rem;
$h2-font-size: (35px/$font-size-root)*1rem;
$h3-font-size: (25px/$font-size-root)*1rem;
$h4-font-size: (20px/$font-size-root)*1rem;

$grid-gutter-width: (30px/$font-size-root)*1rem;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$input-btn-padding-y: (10px/$font-size-root)*1rem;
$input-btn-padding-x: (15px/$font-size-root)*1rem;
$input-btn-focus-width: 0;
$input-btn-focus-color-opacity: 0;
$input-border-radius: 0;
$btn-border-radius: 0;
$input-btn-line-height: (20/16);
$input-btn-font-size: (16px/$font-size-root)*1rem;
$input-btn-border-width: 0;

$input-border-width: 1px;

$breadcrumb-font-size: (14px/$font-size-root)*1rem;
$breadcrumb-item-padding-x: 0.25rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-divider: quote("|");

$accordion-padding-y: (14px/$font-size-root)*1rem;
$accordion-padding-x: 0;
$accordion-color: $primary;
$accordion-border-width: 3px;
$accordion-border-color: $gray-200;
$accordion-body-padding-y: (25px/$font-size-root)*1rem;
$accordion-body-padding-x: (40px/$font-size-root)*1rem;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: #4A4A49;
$accordion-button-focus-box-shadow: none;
$accordion-icon-width: (30px/$font-size-root)*1rem;
$accordion-icon-color: $primary;
$accordion-icon-active-color: $primary;
$accordion-icon-transform: rotate(0deg);

$accordion-button-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M3 3v30h30V3Zm23.333 20.983-2.35 2.35L18 20.35l-5.983 5.983-2.35-2.35L15.65 18l-5.983-5.983 2.35-2.35L18 15.65l5.983-5.983 2.35 2.35L20.35 18Z" transform="translate(-3 -3)" fill="#be1627"/></svg>');
$accordion-button-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M3 3v30h30V3Zm23.333 20.983-2.35 2.35L18 20.35l-5.983 5.983-2.35-2.35L15.65 18l-5.983-5.983 2.35-2.35L18 15.65l5.983-5.983 2.35 2.35L20.35 18Z" transform="translate(-3 -3)" fill="#be1627"/></svg>');

$modal-backdrop-bg: white;
$modal-backdrop-opacity: 0.8;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;

$btn-close-width: 30px;
$btn-close-height: 30px;
$btn-close-bg: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M3 3v30h30V3Zm23.333 20.983-2.35 2.35L18 20.35l-5.983 5.983-2.35-2.35L15.65 18l-5.983-5.983 2.35-2.35L18 15.65l5.983-5.983 2.35 2.35L20.35 18Z" transform="translate(-3 -3)" fill="#be1627"/></svg>');
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;

$slick-prev-character: "";
$slick-next-character: "";
$slick-opacity-default: 1;
$slick-opacity-not-active: 1;
$slick-dot-color: white;
$slick-dot-color-active: $primary;
$slick-dot-size: 10px;

$gbwrdg-corner-size: (80px/$font-size-root)*1rem;
$gbwrdg-corner-size-content: (75px/$font-size-root)*1rem;
