.ce_hyperlink--pagelink,
.ce_hyperlink--projectlink {
  figure {
    position: relative;
  }
  figcaption {
    position: absolute;
    bottom: (7px/$font-size-root)*1rem;
    left: (7px/$font-size-root)*1rem;
    margin: 0;
    padding: (4px/$font-size-root)*1rem (8px/$font-size-root)*1rem;
    font-size: (14px/$font-size-root)*1rem;
    font-weight: 600;
    background: rgba(244, 244, 244, 0.75);
    color: #4a4a49;
  }
  @include media-breakpoint-down(md) {
    .ce_bs_gridStart > div + div > & {
      margin-top: $grid-gutter-width;
    }
  }
}

.ce_hyperlink--pagelink {
  a {
    display: block;
    background-color: $primary;
    transition: background-color 0.1s;
    &:hover,
    &:focus {
      background: $secondary;
    }
  }
  figure {
    margin-bottom: 0;
  }
  figcaption {
    max-width: calc(100% - #{((14px/$font-size-root)*1rem)});
  }
  .ce_hyperlink--pagelink__text {
    display: block;
    padding: (19px/$font-size-root)*1rem (82px/$font-size-root)*1rem (19px/$font-size-root)*1rem (15px/$font-size-root)*1rem;
    font-size: $h3-font-size;
    line-height: (32/25);
    font-weight: bold;
    color: white;
    background-image: url(escape-svg('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path d="m16 32-2.1-2.15L26.25 17.5H0v-3h26.25L13.9 2.15 16 0l16 16Z" fill="#fff" stroke="#fff" stroke-width="1"/></svg>'));
    background-repeat: no-repeat;
    background-size: (20px/$font-size-root)*1rem auto;
    background-position: right (47px/$font-size-root)*1rem center;
  }
}

.ce_hyperlink--projectlink {
  background: $gray-200;
  .image_container {
    position: relative;
    margin-bottom: 0;
  }
  figcaption {
    max-width: calc(100% - #{($gbwrdg-corner-size-content + (9px/$font-size-root)*1rem)});
  }
  &__label {
    position: absolute;
    z-index: 1;
    top: (15px/$font-size-root)*1rem;
    left: 0;
    padding: (8px/$font-size-root)*1rem (15px/$font-size-root)*1rem;
    min-width: (97px/$font-size-root)*1rem;
    background: $gray-600;
    color: white;
    font-size: (16px/$font-size-root)*1rem;
    line-height: (24/16);
    font-weight: 600;
    text-transform: uppercase;
  }
  &__text {
    padding: (22px/$font-size-root)*1rem (15px/$font-size-root)*1rem (20px/$font-size-root)*1rem;
    &__description {
      font-size: (16px/$font-size-root)*1rem;
      line-height: (24/16);
      margin-bottom: (14/16)*1em;
    }
    > p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  h3 {
    margin-bottom: (20px/$font-size-root)*1rem;
    color: $body-color;
  }
}
