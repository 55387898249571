h1 {
  margin-bottom: (24px/$font-size-root)*1rem;
}
* + .h1 {
  margin-top: (100px/$font-size-root)*1rem;
}
h2 {
  margin-bottom: (37px/$font-size-root)*1rem;
  * + &,
  .block + .block > &:first-child {
    margin-top: (70px/$font-size-root)*1rem;
  }
}
h3 {
  margin-bottom: (30px/$font-size-root)*1rem;
  font-weight: bold;
  * + &,
  .block + .block > &:first-child {
    margin-top: (64px/$font-size-root)*1rem;
  }
}
h4 {
  margin-bottom: 1rem;
  font-weight: bold;
  * + &,
  .block + .block > &:first-child {
    margin-top: (50px/$font-size-root)*1rem;
  }
}

img {
  @include img-fluid();
}

main,
footer {
  a {
    font-weight: 600;
  }
}

table {
  @extend .table;
  @extend .table-striped;
}

.ce_text,
.ce_list {
  li {
    + li {
      margin-top: (4px/$font-size-root)*1rem;
    }
  }
}

main {
  *:not(.ce_image--banner) > .image_container {
    img {
      clip-path: polygon(
        0% 0%,
        100% 0%,
        100% calc(100% - $gbwrdg-corner-size-content),
        calc(100% - $gbwrdg-corner-size-content) 100%,
        0% 100%
      );
    }
  }
  .image_container {
    figcaption {
      margin-top: (10px/$font-size-root)*1rem;
      max-width: calc(100% - #{($gbwrdg-corner-size-content + (7px/$font-size-root)*1rem)});
      font-size: (14px/$font-size-root)*1rem;
      color: $gray-600;
    }
  }
}

main > .inside > .mod_article > .ce_text:first-child > h1,
main > .inside > .mod_article:not(.container) > .container > .ce_text:first-child > h1 {
  &,
  & ~ p {
    text-align: center;
  }
  ~ p {
    font-size: (20px/$font-size-root)*1rem;
    color: $secondary;
  }
}

$footer-font-size: (16px/$font-size-root)*1rem;
$footer-line-height: (26/16);
footer {
  font-size: $footer-font-size;
  line-height: $footer-line-height;
  strong {
    font-weight: 600;
  }
  p {
    margin-bottom: $footer-line-height*$footer-font-size;
  }
}
