.mod_article {
  @extend .accordion-flush;
}

.accordion-button {
  padding-bottom: (30px/$font-size-root)*1rem;
  font-size: $h2-font-size;
  font-weight: 600;
  &:hover {
    &:after {
      background-image: url(escape-svg('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path d="M3 3v30h30V3Zm23.333 20.983-2.35 2.35L18 20.35l-5.983 5.983-2.35-2.35L15.65 18l-5.983-5.983 2.35-2.35L18 15.65l5.983-5.983 2.35 2.35L20.35 18Z" transform="translate(-3 -3)" fill="#{$link-hover-color}"/></svg>'));
    }
  }
  &.collapsed {
    &:hover {
      color: $link-hover-color;
    }
  }
  &:not(.collapsed) {
    box-shadow: none;
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.accordion-item {
  *:not(.accordion-item) + & {
    margin-top: (70px/$font-size-root)*1rem;
  }
}
