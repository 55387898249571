.mod_breadcrumb {
  font-size: $breadcrumb-font-size;
  color: $breadcrumb-active-color;
  &.block + .block {
    margin-top: (65px/$font-size-root)*1rem;
  }
  &.block.visually-hidden + .block {
    margin-top: 0;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
  }
  .infotext {
    padding-right: $breadcrumb-item-padding-x;
  }
  a {
    font-weight: inherit;
    color: inherit;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  .breadcrumb-item {
    &.active {
      text-decoration: underline;
    }
  }
}
